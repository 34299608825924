import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

import ProjectCard from '../components/case-small'
import CustomReactShare from '../components/custom-react-share'
import SEO from '../components/seo'
import SectionHeading from '../components/section-header'
import { SimilarItemsGenerator } from '../models/item-similarity-generator'

const Article = styled.article`
  ${tw``};
`
const Header = styled.section`
  ${tw`w-full md:w-2/3 mx-auto text-center my-12 md:mb-16`};
`
const Tags = styled.div`
  ${tw`text-sm font-serif font-semibold tracking-wide uppercase`};
`
const Concept = styled.span`
  ${tw`text-blue-light`};
`
const Title = styled.h1`
  ${tw`text-4xl md:text-5xl text-black dark:text-white leading-tight font-normal mt-5 mb-3 md:my-4`};
`
const PublishDate = styled.div`
  ${tw`mt-0 md:mt-0`};
`
const Image = styled(GatsbyImage)`
  ${tw`text-red mt-6 mb-8 md:mb-12 rounded-sm`};
`
const Technologies = styled.section`
  ${tw`w-full lg:mx-auto font-sans mt-4`};
`

const Content = styled.section`
  ${tw`w-full lg:w-3/4 xl:w-2/3 lg:mx-auto font-sans`};
`

const Section = styled.section`
  ${tw`mb-4 mt-8`};
`

const OtherWork = styled.div`
  ${tw`flex flex-wrap my-4`};
`

class PortfolioEntryTemplate extends React.Component {
  render() {
    const entry = get(this, 'props.data.contentfulPortfolioEntry')
    const { tags, slug } = entry
    const relatedWork = get(this, 'props.data.relatedWork.edges').map(
      ({ node }) => node
    )
    const similarWork = new SimilarItemsGenerator(relatedWork, slug)
      .setTags(tags)
      .getItems()

    return (
      <>
        <SEO
          title={entry.title}
          description={entry.description}
          image={entry.listImage.gatsbyImageData}
          pathname={this.props.location.pathname}
        />
        <Article id="main">
          <Header>
            <Tags>
              {entry.tags.map((tag, i) => (
                <span className="mb-0" key={tag}>
                  {tag}
                  {i !== entry.tags.length - 1 && <span>&nbsp;·&nbsp;</span>}
                </span>
              ))}
              {entry.concept === true && (
                <Concept>&nbsp;·&nbsp;Concept</Concept>
              )}
            </Tags>
            <Title>{entry.title}</Title>
            <PublishDate>{entry.publishDate}</PublishDate>
            <Technologies
              dangerouslySetInnerHTML={{
                __html: entry.technologies.childMarkdownRemark.html,
              }}
            />
          </Header>
          <Image
            alt={entry.title}
            title={entry.title}
            image={entry.heroImage.gatsbyImageData}
          />
          <Content
            dangerouslySetInnerHTML={{
              __html: entry.body.childMarkdownRemark.html,
            }}
          />
          <CustomReactShare
            title={entry.title}
            url={`${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}`}
          />
          <Section id="otherWork">
            <SectionHeading heading="Related Projects" />
            <OtherWork>
              {similarWork.map(entry => {
                return <ProjectCard entry={entry} key={entry.slug} />
              })}
            </OtherWork>
          </Section>
        </Article>
      </>
    )
  }
}

export default PortfolioEntryTemplate

export const pageQuery = graphql`
  query PortfolioEntryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPortfolioEntry(slug: { eq: $slug }) {
      title
      slug
      concept
      clientName
      publishDate(formatString: "MMMM Do, YYYY")
      tags
      heroImage {
        gatsbyImageData(
          quality: 90
          width: 1200
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
      listImage {
        gatsbyImageData(
          quality: 90
          width: 1200
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
      description
      body {
        childMarkdownRemark {
          html
        }
      }
      technologies {
        childMarkdownRemark {
          html
        }
      }
    }
    relatedWork: allContentfulPortfolioEntry(
      filter: { slug: { ne: $slug }, draft: { ne: true } }
      sort: { fields: [publishDate], order: [DESC] }
      limit: 24
    ) {
      edges {
        node {
          title
          clientName
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          listImage {
            gatsbyImageData(
              quality: 90
              width: 1200
              height: 600
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
          projectColor
          description
        }
      }
    }
  }
`
