import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

const Image = styled(GatsbyImage)`
  ${tw`rounded-sm`};
`

const Wrapper = styled.div`
  ${tw`w-full pr-2 md:w-1/2 lg:w-1/3 md:pr-8 mb-6`};
`
const Title = styled.h3`
  ${tw`leading-normal font-semibold font-sans py-0 my-2 text-xl`};
`
const Description = styled.p`
  ${tw`my-2 text-base`};
`
const ProjectTags = styled.p`
  ${tw`my-2 font-semibold tracking-wide uppercase text-blue font-sans text-xs`};
`

const ImageContainer = styled.div`
  ${tw`w-full xl:w-full`};
`

const SmallCase = ({ entry }) => (
  <Wrapper>
    {entry.listImage && (
      <ImageContainer>
        <Link className="leading-normal py-0" to={`/portfolio/${entry.slug}`}>
          <Image
            alt={entry.title}
            title={entry.title}
            image={entry.listImage.gatsbyImageData}
          />
        </Link>
      </ImageContainer>
    )}
    <Title>
      <Link className="leading-normal py-0" to={`/portfolio/${entry.slug}`}>
        {entry.title}
      </Link>
    </Title>
    <Description>{entry.description}</Description>
    <ProjectTags>
      {entry.tags.slice(0, 3).map((tag, i) => (
        <span className="mb-0" key={tag}>
          {tag}
          {i !== entry.tags.length - 1 && <span>&nbsp;·&nbsp;</span>}
        </span>
      ))}
    </ProjectTags>
  </Wrapper>
)

export default SmallCase
